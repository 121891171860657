<template>
  <div></div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { logout } from '../../apollo/api/auth';
export default {
  name: 'logout',
  computed: {
    ...mapGetters(['token']),
  },
  methods: {
    ...mapMutations(['setToken', 'setUser']),
  },
  async created() {
    try {
      await logout(this.token);
    } catch (e) {
      console.log('Failed to logout:', e);
    }
    this.setToken(null);
    this.setUser({ name: '', email: '', permissions: [] });
    this.$router.push('login');
  },
  mounted() {},
};
</script>

<style lang="scss"></style>
